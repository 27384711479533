import {useQuery} from "react-query"
import { Photographer } from "./photographer";
import { callGet } from "../makeApiCall";

export const useSearchQuery = (term: string) => {
  return useQuery({
    queryKey: [ 'photographers-search', term ],
    cacheTime: 1000,
    staleTime: Infinity,
    refetchInterval: false,
    queryFn: async () => {
      return await callGet<Photographer[]>(`/api/photographers/search?term=${encodeURIComponent(term)}`);
    },
  })
}
