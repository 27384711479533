import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Login/Login';
import ProtectedRoute from './ProtectedRoute';
import SignedIn from './SignedIn';


function App() {
  return (
    <div className="">
      <Switch>
        <Route path="/login">
          <Login />
        </Route>

        <ProtectedRoute path="/">
          <SignedIn />
        </ProtectedRoute>

      </Switch>
    </div>
  );
}

export default App;
