import { Image, Variant } from "./image";
import { widthForHeight } from "./widthForHeight";
import { Blurhash } from "react-blurhash";
import {useState} from "react";
import {motion} from "framer-motion";

const ImageWithHash = ({ image, variant, height }: { height: number, image: Image, variant: Variant }) => {
  const dim = widthForHeight(height, image.original);
  const [ showImage, setShowImage ] = useState(false);

  return (
    <div className="relative" style={{ width: dim.width, height: dim.height }} onClick={() => setShowImage(!showImage)}>
      <div className="absolute">
        <Blurhash
          hash={image.hash}
          width={dim.width}
          height={dim.height}
          resolutionX={32}
          resolutionY={32}
          punch={1} />
      </div>

      <div className="absolute z-10">
        <motion.img
          src={variant.url}
          initial={{ opacity: 0.01 }}
          animate={showImage ? { opacity: 1, display: "initial" } : { opacity: 0.01 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          onLoad={() => setShowImage(true)}

          style={{ width: dim.width, height: dim.height }} />
      </div>
    </div>
  );
};

export default ImageWithHash;
