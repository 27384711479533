import { initReactQueryAuth } from "react-query-auth";
import { storage } from "./auth-storage";
import { handleApiResponse } from "./handleApiResponse";

export interface User {
  id: string;
  email: string;
  role: string;
}

interface AuthResponse {
  user: User;
  jwt: string;
}

async function getUserProfile() {
  const res = await fetch(`/api/auth/me`, {
    headers: {
      Authorization: `Bearer ${storage.getToken()}`,
    },
  });

  return await handleApiResponse<User>(res);
}

interface LoginArgs {
  email: string;
  password: string;
}

async function loginWithEmailAndPassword(
  data: LoginArgs,
): Promise<AuthResponse> {
  const res = await fetch(`/api/auth/login`, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });

  return await handleApiResponse<AuthResponse>(res);
}

async function logoutFn() {
  storage.clearToken();
}

export async function handleUserResponse(data: AuthResponse) {
  const { jwt, user } = data;
  storage.setToken(jwt);
  return user;
}

async function loadUser() {
  let user = null;

  if (storage.getToken()) {
    try {
      const data = await getUserProfile();
      user = data;
    } catch {
      storage.clearToken();
      return null;
    }
  }
  return user;
}

async function loginFn(data: LoginArgs) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

const { AuthProvider, useAuth } = initReactQueryAuth<User | null>({
  logoutFn,
  registerFn: async () => null,
  loginFn,
  loadUser,
  waitInitial: true,
});

export { AuthProvider, useAuth };
