import {Query, useMutation, useQueryClient} from "react-query"
import { callPost } from "../makeApiCall";
import {Photographer} from "./photographer";

export const useEnableFeature = () => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, flag }: { id: number, flag: string }) => {
      return await callPost<Photographer>(`/api/photographers/${encodeURIComponent(id)}/enable-early-access`, { flag });
    },
    onSuccess: data => {
      const cache = client.getQueryCache();
      const allSearches = cache.findAll([ "photographers-search" ]) as Query<Photographer[]>[];

      for (const search of allSearches) {
        search.setData(current => {
          const newData = current.slice();
          const p = newData.find(a => a.id == data.id);
          if (p) {
            Object.assign(p, data);
          }

          return newData;
        });
      }
    },
  });
}
