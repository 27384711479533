import { useLatestImages } from "./get-latest-images";
import Gallery from "react-photo-gallery";
import ImageWithHash from "../Photographers/ImageWithHash";

const LatestImages = () => {
  const { data, isError, isLoading } = useLatestImages();

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <div>
        There was a problem... Try again.
      </div>
    );
  }

  const photos = data.map(image => ({
    src: image.sm.url,
    width: image.sm.width,
    height: image.sm.height,
    image: image,
  }));

  return (
    <div>
      <Gallery
        photos={photos}
        renderImage={({ photo, index, margin }) => (
          <div style={{ margin }}>
            <ImageWithHash image={photos[index].image} height={photo.height} variant={photos[index].image.sm} />
          </div>
        )}
      />
    </div>
  );
}

export default LatestImages;
