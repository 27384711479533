import SessionImage from "../images/session.svg";
import { useForm } from "react-hook-form";
import {useAuth} from "../auth";
import { Redirect } from "react-router-dom";
import {useState} from "react";

const Login = () => {
  const { login, isLoggingIn, user } = useAuth();
  const { register, handleSubmit } = useForm();
  const [ error, setError ] = useState(false);

  const onSubmit = async (data: { email: string, password: string }) => {
    setError(false);

    try {
      await login(data);
    } catch {
      setError(true);
    }
  };

  if (user != null) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="mt-6 flex justify-center">
          <img src={SessionImage} className="w-1/2" />
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" defaultValue="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                {...register("email")}
                disabled={isLoggingIn}
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                {...register("password")}
                disabled={isLoggingIn}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
              />
            </div>
          </div>

          <div>
            {error && (
              <div className="my-3 bg-red-100 text-red-700 p-3 rounded-md text-sm">
                Email address or password invalid.
              </div>
            )}
            <button
              disabled={isLoggingIn}
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {isLoggingIn ? "Signing in..." : "Sign in"}
            </button>
          </div>
        </form>
      </div>
    </div>

  );
};

export default Login;
