import {useQuery} from "react-query"
import { Image } from "../Photographers/image";
import { callGet } from "../makeApiCall";

export const useLatestImages = () => {
  return useQuery({
    queryKey: [ 'images-latest' ],
    cacheTime: 1000,
    staleTime: Infinity,
    refetchInterval: false,
    queryFn: async () => {
      return await callGet<Image[]>(`/api/images/latest-images`);
    },
  })
}
