import { useBullQueueStats, BullQueueMetricData } from "./bull-stats-query";

import { Sparklines, SparklinesLine, SparklinesNormalBand, SparklinesReferenceLine } from "react-sparklines";


const SparklinesForBullQueue = ({ data, color, height }: { data: BullQueueMetricData, color: "blue" | "red", height: 10 | 30 }) => {
  const spData: number[] = [];
  for (const d in data.bucket) {
    spData.push(data.bucket[d]);
  }

  let backgroundColor = "white";
  switch (color) {
    case "red": backgroundColor = "#fff7f7"; break;
    case "blue": backgroundColor = "#f9f9ff"; break;

  }

  return (
    <Sparklines data={spData} height={height} margin={3} style={{ background: backgroundColor, borderRadius: 3 }}>
      <SparklinesLine color={color} style={{ fill: "none", strokeWidth: 0.3 }} />
      <SparklinesNormalBand style={{ fill: "#2991c8", fillOpacity: .2 }} />
    </Sparklines>
  );
};


const Stats = () => {
  const BullResults = useBullQueueStats();

  if (BullResults.isLoading) {
    return null;
  }

  if (BullResults.isError) {
    return (
      <div>
        There was a problem... Try again.
      </div>
    );
  }

  return (
    <div>
      <h1 className="text-2xl mt-10">Queue Stats</h1>

      {BullResults.data.map(queue => (
        <div key={queue.name} className="mt-6">
          <h3 className="text-lg">{queue.name}</h3>
          <div className="ml-3 text-sm">
            <div>
              Processed: <span className="font-semibold">{queue.completed.count}</span>
            </div>
            <div>
              Active: <span className="font-semibold">{queue.counts.active}</span>
            </div>
            <div>
              Pending: <span className="font-semibold">{queue.counts.waiting}</span>
            </div>
            <div>
              Failed: <span className="font-semibold">{queue.failed.count}</span>
            </div>

            <div className="w-1/3 space-y-1">
              <SparklinesForBullQueue data={queue.completed} color="blue" height={30} />
              <SparklinesForBullQueue data={queue.failed} color="red" height={10} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stats;
