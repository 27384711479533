import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "./auth";

const ProtectedRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { user } = useAuth();

  return <Route
    {...rest}
    render={({ location }) => {
      if (!user) {
        return <Redirect to={{
          pathname: "/login",
          state: { from: location },
        }} />
      }

      return children;
    }}
  />;
};

export default ProtectedRoute;
