import {useQuery} from "react-query"
import { callGet } from "../makeApiCall";

export type BullQueueMetricData = {
  count: number;
  bucket: { [n: number]: number };
};

export type BullQueueStat = {
  name: string;
  counts: {
    waiting: number;
    active: number;
    completed: number;
    failed: number;
    delayed: number;
    paused: number;
  };
  completed: BullQueueMetricData;
  failed: BullQueueMetricData;
};

export const useBullQueueStats = () => {
  return useQuery({
    queryKey: [ 'bull-queue-stats' ],
    cacheTime: 1000,
    staleTime: Infinity,
    refetchInterval: 5000,
    queryFn: async () => {
      return await callGet<BullQueueStat[]>(`/api/bull-queue-stats`);
    },
  })
}
