import Card from "./Card";
import { useForm } from "react-hook-form";
import {useState} from "react";
import {useSearchQuery} from "./search-query";

const SearchResults = ({ term }: { term: string }) => {
  const { data, isError, isLoading } = useSearchQuery(term);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <div>
        There was a problem searching... Try again.
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {data.map(photographer => (
        <Card photographer={photographer} key={photographer.id} />
      ))}
    </div>
  );
};

const Photographers = () => {
  const { register, handleSubmit } = useForm();
  const [ term, setTerm ] = useState("");

  const onSubmit = async (data: { term: string }) => {
    const t = data.term.replace(/^mailto:/, "");
    setTerm(t);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full lg:w-1/2">
          <div className="flex items-stretch space-x-3">

            <input
              {...register("term")}
              name="term"
              className="appearance-none rounded w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900"
              placeholder="Search by Email address"
              onFocus={e => e.target.select()}
            />

            <button type="submit" className="btn w-40">Search</button>
          </div>
        </div>
      </form>

      {term?.length > 0 && (
        <div className="mt-6">
          <SearchResults term={term} />
        </div>
      )}
    </div>
  );
};

export default Photographers;
