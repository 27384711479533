import {useQuery} from "react-query"
import { Image } from "./image";
import { callGet } from "../makeApiCall";

export const usePhotographerLatestImages = (photographerId: number) => {
  return useQuery({
    queryKey: [ 'photographer-images-latest', photographerId ],
    cacheTime: 1000,
    staleTime: Infinity,
    refetchInterval: false,
    queryFn: async () => {
      return await callGet<Image[]>(`/api/photographers/${photographerId}/latest-images`);
    },
  })
}
