import { NavLink } from "react-router-dom";
import SessionImage from "./images/session.svg";
import { Switch, Route } from "react-router-dom";
import Photographers from "./Photographers/Photographers";
import LatestImages from "./LatestImages/LatestImages";
import Stats from "./Stats/Stats";
import { useAuth } from "./auth";

const NavClasses = "text-sm uppercase hover:bg-gray-200 px-3 py-1 rounded";
const ActiveNavClasses = "bg-indigo-600 text-white hover:bg-indigo-700";

const SignedIn = () => {
  const { logout } = useAuth();

  return (
    <div>
      <div className="bg-gray-100 flex justify-between items-center px-4 py-3 h-16">
        <div className="flex items-center space-x-3 text-gray-600">
          <img src={SessionImage} className="h-6 mr-4" />

          <NavLink exact to="/" className={NavClasses} activeClassName={ActiveNavClasses}>
            Photographers
          </NavLink>

          <NavLink exact to="/latest-images" className={NavClasses} activeClassName={ActiveNavClasses}>
            Latest Images
          </NavLink>

          <NavLink to="/stats" className={NavClasses} activeClassName={ActiveNavClasses}>
            Stats
          </NavLink>
        </div>
        <div>
          <button onClick={() => logout()}>Log out</button>
        </div>
      </div>
      <div className="my-6 mx-3">
        <Switch>
          <Route path="/latest-images">
            <LatestImages />
          </Route>

          <Route path="/stats">
            <Stats />
          </Route>

          <Route path="/">
            <Photographers />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default SignedIn;
