import { storage } from "./auth-storage";
import { handleApiResponse } from "./handleApiResponse";

const call = async <T>(
  path: string,
  method: "GET" | "POST" = "GET",
  body?: any,
) => {
  const options: RequestInit = {
    method,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${storage.getToken()}`,
    },
  };

  if (method == "POST") {
    options.body = JSON.stringify(body);
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
    };
  }

  const res = await fetch(path, options);
  return await handleApiResponse<T>(res);
};


export const callGet = async <T>(path: string) => {
  return await call<T>(path, "GET");
};

export const callPost = async <T>(path: string, body?: any) => {
  return await call<T>(path, "POST", body);
};
