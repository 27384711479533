import { useState } from "react";
import { useImpersonatePhotographer } from "./impersonate-query";
import { usePhotographerLatestImages } from "./latest-images-query";
import { Photographer, Flag } from "./photographer";
import { Image } from "./image";
import { useEnableFeature } from "./enable-feature-query";

import ImageWithHash from "./ImageWithHash";

const pillClasses = "px-2 py-1 bg-gray-100 rounded-sm";

const Plan = ({ photographer }: { photographer: Photographer }) => {
  let bgAndTextClass = "bg-gray-100 text-gray-700";
  let planText: string = photographer.planStatus;

  switch (photographer.planStatus) {
    case "active":
      planText = photographer.currentPlan;
      bgAndTextClass = "bg-green-100 text-green-700";
      break;

    case "trialing":
      planText = "Trialing";
      bgAndTextClass = "bg-indigo-100 text-indigo-700";
      break;

    case "canceled":
      planText = "Canceled";
      bgAndTextClass = "bg-red-100 text-red-700";
      break;
  }

  return (
    <span className={`px-2 ${bgAndTextClass} rounded-sm text-sm`}>
      {planText}
    </span>
  );
};

const ImpersonateButton = ({
  photographer,
}: {
  photographer: Photographer;
}) => {
  const { isLoading, mutateAsync } = useImpersonatePhotographer();

  const onClick = async () => {
    const res = await mutateAsync(photographer.id);
    window.open(`https://app.usesession.com/#token=${res.jwt}`, "_blank");
  };

  const onClickBeta = async () => {
    const res = await mutateAsync(photographer.id);
    window.open(`https://app-beta-test.usesession.com/#token=${res.jwt}`, "_blank");
  };

  return (
    <div className="flex items-center space-x-2">
      <button disabled={isLoading} onClick={onClickBeta} className="btn">
        Impersonate BETA
      </button>
      <button disabled={isLoading} onClick={onClick} className="btn">
        Impersonate
      </button>
    </div>
  );
};

const widthForHeight = (
  height: number,
  size: { height: number; width: number },
) => {
  return {
    width: (size.width * height) / size.height,
    height,
  };
};

const ImageRow = ({ image }: { image: Image }) => {
  const dim = widthForHeight(200, image.original);

  return (
    <div className="mt-3 flex items-start space-x-4">
      <div>
        <div className="text-xs mb-2">Blur Hash</div>

        <ImageWithHash image={image} height={dim.height} variant={image.sm} />
      </div>

      <div>
        <div className="text-xs mb-2">Small</div>

        <a href={image.sm.url} target="_blank">
          <img src={image.sm.url} height={dim.height} width={dim.width} />
        </a>
      </div>

      <div>
        <div className="text-xs mb-2">Medium</div>

        <a href={image.md.url} target="_blank">
          <img src={image.md.url} height={dim.height} width={dim.width} />
        </a>
      </div>

      <div>
        <div className="text-xs mb-2">Large</div>

        <a href={image.lg.url} target="_blank">
          <img src={image.lg.url} height={dim.height} width={dim.width} />
        </a>
      </div>
    </div>
  );
};

const ImagesForPhotographer = ({
  photographerId,
}: {
  photographerId: number;
}) => {
  const { data, isError, isLoading } =
    usePhotographerLatestImages(photographerId);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <div>There was a problem... Try again.</div>;
  }

  return (
    <div className="space-y-6">
      {data.map(image => (
        <div key={image.id}>
          <ImageRow image={image} />
        </div>
      ))}
    </div>
  );
};

const EarlyAccess = ({ photographer }: { photographer: Photographer }) => {
  const [currentFlag, setCurrentFlag] = useState<Flag | null>(null);


  const enableFeatureMutation = useEnableFeature();

  const onEnableCurrent = async () => {
    if (currentFlag == null) return;

    await enableFeatureMutation.mutateAsync({
      flag: currentFlag.flag,
      id: photographer.id,
    });

    setCurrentFlag(null);
  };

  return (
    <div className="text-sm">
      {photographer.early_access.enrolled.length > 0 && (
        <div className="mb-1">
          Enrolled:{" "}
          <div className="inline-flex space-x-1 items-center">
            {photographer.early_access.enrolled.map(f => (
              <div
                key={f.flag}
                className="px-2 bg-green-50 text-gray-700 rounded-sm text-sm">
                {f.name}
              </div>
            ))}
          </div>
        </div>
      )}

      {photographer.early_access.availavle.length > 0 && (
        <div className={currentFlag == null ? "" : "opacity-30"}>
          Enable:{" "}
          <div className="inline-flex space-x-1 items-center">
            {photographer.early_access.availavle.map(f => (
              <button
                disabled={currentFlag != null}
                onClick={() => setCurrentFlag(f)}
                key={f.flag}
                className="px-2 border shadow-sm rounded-sm text-sm">
                {f.name}
              </button>
            ))}
          </div>
        </div>
      )}

      {currentFlag != null && (
        <div className="mt-3 border p-2">
          Enable {currentFlag.name}?
          <div className="mt-1 flex items-center space-x-2">
            <button disabled={enableFeatureMutation.isLoading} onClick={onEnableCurrent} className="btn">Enable, {currentFlag.sends_email ? "and send email" : "does not send email"}</button>
            <button disabled={enableFeatureMutation.isLoading} onClick={() => setCurrentFlag(null)} className="btn bg-white text-black border">Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Card = ({ photographer }: { photographer: Photographer }) => {
  const [showLatestImages, setShowLatestImages] = useState(false);

  return (
    <div className="border p-3 rounded">
      <div className="flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:justify-between sm:items-start">
        <div className="flex space-x-3 items-start">
          <div className="flex flex-col">
            <div className="flex items-center space-x-2">
              <a
                href={photographer.bookingSiteLink}
                target="_blank"
                className="underline">
                {photographer.name}
              </a>
              <Plan photographer={photographer} />
            </div>
            <div className="text-sm flex space-x-6">
              <div>{photographer.email}</div>
              <div>ID: {photographer.id}</div>
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-3">
          <ImpersonateButton photographer={photographer} />
          <a
            href={photographer.stripeLink}
            target="_blank"
            className="px-3 py-1 bg-indigo-100 text-indigo-900 rounded">
            View in Stripe
          </a>
        </div>
      </div>

      <div className="mt-3 flex justify-between items-center text-sm">
        <div>Created on {photographer.createdOn}</div>
      </div>

      {photographer.activeFeatures.length > 0 && (
        <div className="text-sm grid grid-cols-3 sm:grid-cols-4 gap-2 mt-3">
          {photographer.activeFeatures.map((feature, idx) => (
            <div className={pillClasses} key={idx}>
              {feature}
            </div>
          ))}
        </div>
      )}

      <div className="mt-6 text-xs">
        <button
          className="underline"
          onClick={() => setShowLatestImages(!showLatestImages)}>
          {showLatestImages ? "Hide" : "Show"} Latest Images
        </button>

        {showLatestImages && (
          <div className="mt-8">
            <ImagesForPhotographer photographerId={photographer.id} />
          </div>
        )}
      </div>

      <div className="mt-4">
        <EarlyAccess photographer={photographer} />
      </div>
    </div>
  );
};

export default Card;
